import React from "react"
// import { Link } from "gatsby" // we use wrapper one from plugin = gatsby-plugin-intl
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { graphql } from "gatsby"

// s4n custom code
import { useIntl } from "gatsby-plugin-intl"
// import DrupalModuleParagraphsContainer from './../components/drupal/module/paragraphs/container'
// import Widgets from "./../components/widgets"
// import Team from './../components/s4n/Team/index-static';
// import loadable from "@loadable/component"
// import ImagesIllustrationsLeafBg from './../assets/images/illustrations/leaf-bg.png';
// import Portfolio from './../components/s4n/Portfolio'
// import Partners from './../components/s4n/Partners'
// import Testimonials from './../components/s4n/Testimonials'

// Subpages
import kebabCase from "lodash.kebabcase"
import Link from "./../components/s4n/Link"
// import Page from "./../model/node/page"
import Hero from './../components/s4n/ProjectSpecific/Hero'
// import useReplacementNode from './../hooks/drupal/parseContentProcessor/useReplacement/Node'

import ImageUtils from './../utils/gatsby/image'
import ImageS4n from './../components/s4n/image'



// const PAGE_2_IMAGE = [
//   `custom/page/about-us.jpg`,     // 24
//   `custom/page/methodology.jpg`,  // 15
//   `custom/page/price-list.jpg`,   // 23
// ];



const IndexPage = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    return (
      <Layout 
        // node={nodeInLanguage}
      >

          <SEO 
            // nodeId={nodeInLanguage.drupal_internal__nid} 
            // title={nodeInLanguage.title} 
            title={t({ id: `siteMetadata_title_home_page` })} 
            // field_metatag={nodeInLanguage.field_metatag}
          />

          <Hero />

          <section className="section-padding o-nas">
            <div className="container my-5">
                <div className="row">
                    <div className="col-sm-6 d-flex flex-column justify-content-center">
                        <h2 className="banner-title">Klimatyzacja i wentylacja</h2>
                        <p>Oferujemy nasze usługi z zakresu klimatyzacji i wentylacji. Zapewniamy kompleksowy montaż, serwis oraz obsługę systemów klimatyzacyjnych.</p>
                    </div>
                    <div className="col-sm-6">
                        <ImageS4n src={`custom/proklima/slider-1.jpeg`} alt={``} _className="pb-5" _maxWidth="100%" _maxHeight="5rem" />
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-main">
              <div className="container my-5">
                <div className="row">
                    <div className="col-sm-6 order-1 order-sm-0">
                      <ImageS4n src={`custom/proklima/slider-2.jpeg`} alt={``} _className="pb-5" _maxWidth="100%" _maxHeight="5rem" />
                    </div>
                    <div className="col-sm-6 d-flex flex-column justify-content-center">
                        <h2 className="banner-title">Profesjonalny montaż</h2>
                        <p>Nasz zespół posiada ogromną wiedzę z zakresu klimatyzacji potwierdzone certyfikatami. Posiadamy uprawnienia niezbędne do przeprowadzenia profesjonalnej usługi.</p>
                    </div>
                </div>
              </div>
            </div>
            <div className="container my-5">
                <div className="row">
                  <div className="col-sm-6 d-flex flex-column justify-content-center">
                      <h2 className="banner-title">Dobór urządzeń</h2>
                      Oferujemy montaż instalacji freonowych ( przygotowanie pod urządzenia klimatyzacyjne ), montaż urządzeń klimatyzacyjnych na gotowych instalacjach, dobór urządzeń pod kubaturę pomieszczenia, szeroka gama klimatyzatorów (Mitsubishi electric, Daikin, Midea, LG, Fujitsu, Kaisai, Gree i wiele innych).
                    </div>
                    <div className="col-sm-6">
                      <ImageS4n src={`custom/proklima/slider-3.jpeg`} alt={``} _className="pb-5" _maxWidth="100%" _maxHeight="5rem" />
                    </div>
                </div>
            </div>
          </section>
          <section className="section-padding">
            <div className="container my-5">
                <div className="row">
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <h2 className="banner-title">Lokalizacja</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.902094905038!2d20.297282315719613!3d50.12555397943184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716361c3cd07ea1%3A0xf32ea78982e0edd2!2sStr%C4%99goborzyce%2012%2C%2032-125%20Str%C4%99goborzyce!5e0!3m2!1sen!2spl!4v1595491971542!5m2!1sen!2spl" 
                    width="100%" height="550" frameBorder="0" style={{border: 0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10645.299229381806!2d19.90229033127981!3d50.292308826109505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47170001f7418cc3%3A0x8ffc0e082e9d1d8a!2s%C5%9Aciborzyce%2017%2C%2032-353%20%C5%9Aciborzyce!5e0!3m2!1spl!2spl!4v1645446112624!5m2!1spl!2spl" 
                    width="100%" height="550" frameBorder="0" style={{border: 0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                  </div>
                </div>
            </div>
          </section>
    
      </Layout>
    )
}

export default IndexPage

// export const query = graphql`
//   query {
//     allNodePage (
//         filter: {
//           drupal_internal__nid: {
//             eq: 1
//           }
//           # langcode: {
//           #   eq: "pl"
//           # }
//         }
//       ) {
//         edges {
//           node {
//             ...FragmentPageDetails
//           }
//         }
//     }
//   }
// `